<script setup lang="ts">
defineProps<{
  error?: string | undefined;
}>();

const model = defineModel('modelValue', {
  type: String,
});

function handleKeydown(event: KeyboardEvent) {
  if (event.code === 'Space') {
    event.preventDefault();

    model.value = model.value + ' ';
  }
}
</script>

<template>
  <div
    class="flex-1 text-left text-sm font-medium leading-3 text-gray-iron-700"
    :class="$attrs.class"
  >
    <label v-if="$slots.default" class="mb-1.5 block">
      <slot></slot>
    </label>

    <PrimeInputText
      v-model="model"
      v-bind="$attrs"
      class="block w-full"
      size="small"
      :invalid="!!error"
      @keydown.stop="handleKeydown"
    />

    <small class="mt-1 text-left text-xs text-red-600">
      {{ error }}
    </small>
  </div>
</template>
